<template>
  <FormulateForm
    ref="form"
    v-model="payload"
    class="send-reset-password-form fluid v-spacing-3"
    @input="validate"
    @submit="onSubmit"
  >
    <FormulateInput
      type="email"
      :placeholder="$t(`${$options.path}.email`)"
      name="email"
      validation="bail|email|required"
    />

    <AsyncMessage
      :fetching="fetching"
      :message="
        response.error && $t(`errors.${response.error.code || 'default'}`)
      "
    />

    <FormulateInput type="submit" :disabled="hasErrors">
      <EButton :disabled="hasErrors" filled lg>
        {{ $t(`${$options.path}.actions.${resend ? 'resend' : 'send'}`) }}
      </EButton>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
// await new Promise(resolve => setTimeout(resolve, 10000));
// throw 'error';

import { mapActions } from 'vuex';
import { WithIsNewUser } from '@/authentication/mixins';

// components
import AsyncMessage from '@/authentication/components/AsyncMessage.vue';

export default {
  name: 'SendResetPasswordForm',
  components: { AsyncMessage },
  mixins: [WithIsNewUser],
  props: {
    resend: { type: Boolean, default: false },
  },
  data() {
    const { EMAIL_KEY } = this.$options;

    return {
      payload: { email: this.$route.query[EMAIL_KEY] || '' },
      hasErrors: true,

      response: { data: null, error: null },
      fetching: false,
    };
  },
  methods: {
    ...mapActions('authentication', ['sendResetPassword']),

    async validate() {
      const { form } = this.$refs;
      if (!form) return;

      this.hasErrors = await form.hasValidationErrors();
    },

    async onSubmit() {
      const { payload, isNewUser } = this;

      try {
        this.fetching = true;

        const data = await this.sendResetPassword({
          email: payload.email,
          isNewUser,
        });
        this.response.data = data;

        this.$emit('success');
      } catch (error) {
        this.response.error = error;

        this.$emit('failure', error);

        throw error;
      } finally {
        this.fetching = false;
      }
    },
  },

  path: 'c.send-reset-password-form',

  EMAIL_KEY: 'email',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
