export default {
  data() {
    const { IS_NEW_USER_KEY } = this.$options;

    return {
      isNewUser: this.$route.query[IS_NEW_USER_KEY] === 'true',
    };
  },

  IS_NEW_USER_KEY: 'isNewUser',
};
