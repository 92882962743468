<template>
  <div class="reset-password-sent d-flex-c d-flex-a-c">
    <div v-show="display === $options.DISPLAY_DEFAULT"><slot /></div>

    <template v-if="display === $options.DISPLAY_SUCCESS">
      <SuccessMessage
        :title="$t(`${$options.path}.title`)"
        :message="$t(`${$options.path}.message`)"
        class="fluid"
      />

      <EButton :to="{ name: 'home' }" lg class="mt-4">
        {{ $t('g.go-back') }}
      </EButton>
    </template>
  </div>
</template>

<script>
// components
import SuccessMessage from '@/components/utils/Message/SuccessMessage.vue';

export default {
  name: 'ResetPasswordSent',
  components: { SuccessMessage },
  data() {
    const { DISPLAY_DEFAULT } = this.$options;

    return {
      display: DISPLAY_DEFAULT,

      defaultSlot: null,
    };
  },
  created() {
    let { default: defaultSlot } = this.$slots;
    if (!defaultSlot || defaultSlot.length === 0) return;
    defaultSlot = defaultSlot[0];
    if (!defaultSlot.context) return;
    defaultSlot = defaultSlot.context;

    this.defaultSlot = defaultSlot;

    this.defaultSlot.$on('success', this.onSuccess);
  },
  beforeDestroy() {
    this.defaultSlot.$off('success', this.onSuccess);
  },
  methods: {
    onSuccess() {
      const { DISPLAY_SUCCESS } = this.$options;

      this.display = DISPLAY_SUCCESS;
    },
  },

  path: 'c.reset-password-sent',

  DISPLAY_DEFAULT: 'default',
  DISPLAY_SUCCESS: 'success',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
