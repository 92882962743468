<template>
  <ResetPasswordSent>
    <div class="send-reset-password">
      <OddMessage
        :title="$t(`${$options.path}.title`)"
        :message="$t(`${$options.path}.message`)"
      />

      <SendResetPasswordForm class="mt-4" @success="$emit('success', $event)" />
    </div>
  </ResetPasswordSent>
</template>

<script>
// components
import ResetPasswordSent from './ResetPasswordSent.vue';
// - forms
import SendResetPasswordForm from '@/authentication/forms/SendResetPasswordForm.vue';
// - messages
import OddMessage from '@/components/utils/Message/OddMessage.vue';

export default {
  name: 'SendResetPassword',
  components: { OddMessage, ResetPasswordSent, SendResetPasswordForm },

  path: 'c.send-reset-password',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
