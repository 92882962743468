export default {
  data() {
    const { EMAIL_KEY } = this.$options;

    return {
      email: this.$route.query[EMAIL_KEY] || '',
    };
  },

  EMAIL_KEY: 'email',
};
