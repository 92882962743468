<template>
  <Message
    icon="check"
    icon-color="green"
    message-color="secondary"
    v-bind="$attrs"
  />
</template>

<script>
// components
import Message from './index.vue';

export default {
  name: 'SuccessMessage',
  components: { Message },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
