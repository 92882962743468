export default {
  data() {
    const { ACTION_CODE_KEY } = this.$options;

    return {
      actionCode: this.$route.query[ACTION_CODE_KEY] || '',
    };
  },

  ACTION_CODE_KEY: 'oobCode',
};
