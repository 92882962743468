<template>
  <div class="v-send-reset-password authentication-view">
    <div class="v-send-reset-password__body">
      <SendResetPassword />
    </div>
  </div>
</template>

<script>
// components
import SendResetPassword from '@/authentication/components/SendResetPassword.vue';

export default {
  name: 'VSendResetPassword',
  components: { SendResetPassword },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
